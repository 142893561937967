import actions from './actions';

const initState = {
  total: 0,
  gifts: [],
  error: '',
  loading: false,
};

export default function taskReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_GIFT:
    case actions.DELETE_GIFT:
    case actions.UPDATE_GIFT:
    case actions.CREATE_GIFT:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case actions.DELETE_GIFT_ERROR:
    case actions.LOAD_GIFT_ERROR:
    case actions.UPDATE_GIFT_ERROR:
    case actions.CREATE_GIFT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.LOAD_GIFT_SUCCESS: {
      const { gifts } = state;
      const { data, loadMore } = action;
      const newGifts = loadMore
        ? [...gifts, ...data.gifts]
        : data.gifts;
      // console.log('newProfiles', newProfiles);
      return {
        ...state,
        total: data.total,
        gifts: newGifts,
        loading: false,
        error: '',
      };
    }
    case actions.DELETE_GIFT_SUCCESS: {
      const { gifts, total } = state;
      return {
        ...state,
        loading: false,
        error: '',
        total: total - 1,
        gifts: gifts.filter(t => t._id !== action.id),
      };
    }
    case actions.UPDATE_GIFT_SUCCESS: {
      const { gifts } = state;
      return {
        ...state,
        loading: false,
        error: '',
        gifts: gifts.map(l => {
          if (l._id === action.gift._id) {
            return action.gift;
          }
          return l;
        })
      };
    }
    case actions.CREATE_GIFT_SUCCESS: {
      const { gifts } = state;
      return {
        ...state,
        loading: false,
        error: '',
        gifts: [action.gift, ...gifts],
      };
    }
    default:
      return state;
  }
}
