const actions = {
  LOAD_GIFT: 'WIFIBIZ/LOAD_GIFT',
  LOAD_GIFT_SUCCESS: 'WIFIBIZ/LOAD_GIFT_SUCCESS',
  LOAD_GIFT_ERROR: 'WIFIBIZ/LOAD_GIFT_ERROR',
  CREATE_GIFT: 'WIFIBIZ/CREATE_GIFT',
  CREATE_GIFT_SUCCESS: 'WIFIBIZ/CREATE_GIFT_SUCCESS',
  CREATE_GIFT_ERROR: 'WIFIBIZ/CREATE_GIFT_ERROR',
  UPDATE_GIFT: 'WIFIBIZ/UPDATE_GIFT',
  UPDATE_GIFT_SUCCESS: 'WIFIBIZ/UPDATE_GIFT_SUCCESS',
  UPDATE_GIFT_ERROR: 'WIFIBIZ/UPDATE_GIFT_ERROR',
  DELETE_GIFT: 'WIFIBIZ/DELETE_GIFT',
  DELETE_GIFT_SUCCESS: 'WIFIBIZ/DELETE_GIFT_SUCCESS',
  DELETE_GIFT_ERROR: 'WIFIBIZ/DELETE_GIFT_ERROR',
  loadGift: ({ pageId, limit, skip }) => ({
    type: actions.LOAD_GIFT,
    limit,
    skip,
    pageId,
  }),
  loadGiftSuccess: (data, loadMore = false) => ({
    type: actions.LOAD_GIFT_SUCCESS,
    data,
    loadMore,
  }),
  loadGiftError: (error) => ({
    type: actions.LOAD_GIFT_ERROR,
    error,
  }),
  createGift: (data) => ({
    type: actions.CREATE_GIFT,
    data,
  }),
  createGiftSuccess: (gift) => ({
    type: actions.CREATE_GIFT_SUCCESS,
    gift,
  }),
  createGiftError: (error) => ({
    type: actions.CREATE_GIFT_ERROR,
    error,
  }),
  deleteGift: (id) => ({
    type: actions.DELETE_GIFT,
    id,
  }),
  deleteGiftSuccess: (id) => ({
    type: actions.DELETE_GIFT_SUCCESS,
    id,
  }),
  deleteGiftError: (error) => ({
    type: actions.DELETE_GIFT_ERROR,
    error,
  }),
  updateGift: (id, data) => ({
    type: actions.UPDATE_GIFT,
    id,
    data,
  }),
  updateGiftSuccess: gift => ({
    type: actions.UPDATE_GIFT_SUCCESS,
    gift,
  }),
  updateGiftError: error => ({
    type: actions.UPDATE_GIFT_ERROR,
    error,
  })
};

export default actions;
