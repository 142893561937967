const options = [
  /*
  {
    key: 'wifi-login',
    label: 'sidebar.wifiLogin',
    leftIcon: 'ion-wifi',
    children: [
      {
        key: 'wifi-campaign',
        label: 'sidebar.campaign',
      },
    ],
  },
  */
  {
    key: '',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-home',
  },
  {
    key: 'my-pages',
    label: 'sidebar.myPages',
    leftIcon: 'ion-ios-browsers',
  },
  {
    key: 'campaign',
    label: 'sidebar.wifiMarketing',
    leftIcon: 'ion-wifi',
    children: [
      {
        key: 'campaign',
        label: 'sidebar.campaign',
      },
      {
        key: 'devices',
        label: 'sidebar.devices',
      },
      {
        key: 'accesscode',
        label: 'sidebar.accessCode',
      },
    ],
  },
  {
    key: 'loyalty',
    label: 'sidebar.loyalty',
    leftIcon: 'ion-heart',
    children: [
      {
        key: 'gift-orders',
        label: 'sidebar.giftOrders',
      },
      {
        key: 'gifts',
        label: 'sidebar.gifts',
      },
      {
        key: 'tasks',
        label: 'sidebar.tasks',
      },
    ],
  },
  /**
  {
    key: 'zalo',
    label: 'sidebar.zalo',
    leftIcon: 'ion-location',
    children: [
      {
        key: 'zalo-followers',
        label: 'sidebar.followers',
      },
      {
        key: 'zalo-messages',
        label: 'sidebar.zalo_messages',
      },
      {
        key: 'zalo-bot',
        label: 'sidebar.zaloBot',
      },
      {
        key: 'zalo-message-templates',
        label: 'sidebar.messageTemplates',
      },
      {
        key: 'page-zalo',
        label: 'sidebar.zaloSettings',
      },
    ],
  },
  */
  {
    key: 'guest',
    label: 'sidebar.guests',
    leftIcon: 'ion-person-stalker',
    children: [
      {
        key: 'guests-custom-profile',
        label: 'sidebar.customProfile',
      },
      {
        key: 'sale-leads',
        label: 'sidebar.leads',
        leftIcon: 'ion-flash',
      },
    ],
  },
  {
    key: 'report',
    label: 'sidebar.report',
    leftIcon: 'ion-pie-graph',
    children: [
      {
        key: 'report-overview',
        label: 'sidebar.overview',
      },
      {
        key: 'report-top-users',
        label: 'sidebar.topUsers',
      },
      {
        key: 'report-campaigns',
        label: 'sidebar.campaign',
      },
      {
        key: 'report-visits',
        label: 'sidebar.visitsSummary',
      },
      {
        key: 'report-devices',
        label: 'sidebar.devices',
      },
      {
        key: 'report-software',
        label: 'sidebar.software',
      },
      {
        key: 'report-times',
        label: 'sidebar.times',
      },
      {
        key: 'report-survey',
        label: 'sidebar.survey',
      },
    ],
  }, {
    key: 'media',
    label: 'sidebar.media',
    leftIcon: 'ion-images',
  }, {
    key: 'page-settings',
    label: 'themeSwitcher.settings',
    leftIcon: 'ion-hammer',
    children: [
      {
        key: 'page-notification',
        label: 'sidebar.notification',
      },
      {
        key: 'page-license',
        label: 'sidebar.pageLicense',
      },
      {
        key: 'page-role',
        label: 'sidebar.pageRole',
      },
      /**
      {
        key: 'page-zalo',
        label: 'sidebar.zaloOA',
      },
      */
      {
        key: 'page-integration',
        label: 'sidebar.integration',
      },
    ],
  },
];
export default options;
